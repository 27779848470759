export default {
  pt: {
    appBarBtns: {
      whoIam: 'Quem sou',
      projects: 'Projetos',
      jobs: 'Serviços',
      knowledge: 'Conhecimentos'
    },
    notifications: {
      languageUpdated: 'Idioma alterado para Português',
      themeUpdatedToLight: 'Tema alterado para claro',
      themeUpdatedToDark: 'Tema alterado para escuro',
    },
    components: {
      projectCard: {
        btn: 'Ver Projeto',
      },
    },
    sections: {
      home: {
        greetings: 'Olá, eu sou',
        contactBtn: 'Contate-me',
      },
      whoIAm: {
        description: 'Eu sou Luiz Adolfo. Atualmente trabalho no desenvolvimento de sistemas em micro serviços na linguagem C# (.Net Core 3.1+) voltadas para sistemas de Fintechs, E-commerce entre outras. Nos tempos livres sou freelancer em Front-end(Vue.js) e Back-end(.Net Core). Desenvolvo sites, portfolios, landing pages e sistemas para empresas e perfis profissionais.'
      },
      projects: {
        repositoriesBtn: 'Repositórios no GitHub',
        project1: {
          title: 'Ferdado Engenharia',
          description: 'Um site de portfólio profissional para uma empresa de engenharia.',
        },
        project2: {
          title: 'Patrícia Elizandra',
          description: 'Um site de portfólio profissional para um escritório de advocacia.',
        },
        project3: {
          title: 'Authorization Interceptor',
          description: 'Um pacote .NET simples e leve projetado para agilizar solicitações autenticadas de um HttpClient.',
        },
      },
      jobs: {
        job1: {
          title: 'UI/UX Design',
          description: 'Desenho e modelo interfaces intuitivas e objetivas para que o usuário tenha uma melhor experiência de uso.',
        },
        job2: {
          title: 'Desenvolvimento Front-End',
          description: 'Desenvolvo aplicações Web (SPA, PWA e Landing Page) responsivas e de alto desempenho para sites profissionais, e-commerce, blogs, portfolios, dashboards e painéis administrativos.',
        },
        job3: {
          title: 'Desenvolvimento Back-End',
          description: 'Desenvolvo API\'s rápidas, modernas, seguras e de alta performance em micro-serviços ou monólitos.',
        },
      },
      knowledge: {
        description: '*selecione um dos cards para ler*',
        items: {
          vue: 'Vue.js é um framework JavaScript de código aberto para criar interfaces de usuário (UIs) e single-page-applications (SPAs).',
          vuetify: 'Vuetify é um framework de interface do usuário completa construída em cima do Vue.js. Tem como objetivo fornecer todas as ferramentas necessárias para criar belos aplicativos ricos em conteúdo.',
          html5: 'HTML5 é uma linguagem de marcação usada para estruturar e apresentar conteúdo na internet.',
          js: 'JavaScript é uma linguagem de programação baseada em texto usada tanto no lado do cliente quanto no lado do servidor que permite que você torne as páginas da web interativas.',
          css: 'CSS (Cascading Style Sheets) descreve como os elementos HTML devem ser exibidos na tela ou como devem ser estilizados.',
          jest: 'Jest é um framework de teste para JavaScript projetada para garantir a exatidão de qualquer base de código JavaScript. Ele permite que você escreva testes com uma API acessível, familiar e rica em recursos que fornece resultados rapidamente.',
          npm: 'npm é o maior gerenciador de pacote do mundo. O gerenciador contém mais de 800.000 pacotes de código. Desenvolvedores de código aberto usam o npm para compartilhar software.',
          yarn: 'Yarn é um novo gerenciador de pacotes que substitui o fluxo de trabalho existente para o cliente npm ou outros gerenciadores de pacotes, mantendo-se compatível com o registro npm.',
          github: 'O GitHub é uma plataforma de desenvolvimento de software online usada para armazenar, rastrear e colaborar em projetos de software. Ele permite que os desenvolvedores carreguem seus próprios arquivos de código e colaborem com outros desenvolvedores em projetos de código aberto.',
          gitlab: 'GitLab é um repositório de código aberto e plataforma de desenvolvimento de software colaborativo para grandes projetos de DevOps e DevSecOps.',
          sonar: 'SonarQube é uma plataforma de código aberto desenvolvida pela SonarSource para inspeção contínua da qualidade do código para realizar revisões automáticas com análise estática de código para detectar bugs, code smells em 29 linguagens de programação.',
          grafana: 'Grafana é um aplicativo web multiplataforma de análise de código aberto e visualização interativa. Ele fornece tabelas, gráficos e alertas para a web quando conectado a uma base de dados compatível.',
          dotnet: '.NET é uma plataforma de desenvolvimento de código aberto, multiplataforma e gratuita para criar muitos tipos diferentes de aplicativos. Com o .NET, você pode usar vários idiomas, editores e bibliotecas para criar para Web, dispositivos móveis, desktop, jogos, IoT e muito mais.',
          csharp: 'C# é uma linguagem de programação moderna, orientada a objetos e type safety. O C# permite que os desenvolvedores criem muitos tipos de aplicativos seguros e robustos que são executados no .NET.',
          efcore: 'O Entity Framework (EF) Core é um framework leve, extensível, de código aberto e multiplataforma de acesso a dados do Entity Framework. O EF Core pode servir como um mapeador relacional de objeto (O/RM).',
          nunit: 'NUnit é um framework de testes unitários para todas as linguagens .NET.',
          identityserver: 'IdentityServer é um servidor de autenticação que implementa os padrões OpenID Connect (OIDC) e OAuth 2.0 para ASP.NET Core. Ele foi projetado para fornecer uma maneira comum de autenticar solicitações para todos os seus aplicativos, sejam eles web, nativos, móveis ou endpoints de API.',
          sqlserver: 'O Microsoft SQL Server é um sistema de gerenciamento de banco de dados relacional (RDBMS) que oferece suporte a uma ampla variedade de aplicativos de processamento de transações, inteligência de negócios e análise em ambientes corporativos de TI.',
          postgres: 'O PostgreSQL é um banco de dados relacional de código aberto de classe empresarial avançado que suporta consultas SQL (relacional) e JSON (não relacional).',
          mongo: 'MongoDB é um banco de dados de documentos usado para construir aplicativos de internet altamente disponíveis e escaláveis. Com sua abordagem de esquema flexível, é popular entre as equipes de desenvolvimento que usam metodologias ágeis.',
          redis: 'Redis é um banco de dados em memória distribuído de chave-valor, cache e agente de mensagens, com durabilidade opcional.',
          docker: 'Docker é um conjunto de produtos de plataforma como serviço que usam virtualização de nível de sistema operacional para entregar software em pacotes chamados contêineres. Os contêineres são isolados uns dos outros e agrupam seus próprios softwares, bibliotecas e arquivos de configuração.',
          aws: 'Amazon Web Services, também conhecido como AWS, é uma plataforma de serviços de computação em nuvem, que formam uma plataforma de computação na nuvem oferecida pela Amazon.com. Os serviços são oferecidos em várias áreas geográficas distribuídas pelo mundo.',
          rabbitmq: 'RabbitMQ é um intermediador de mensagens. Ele oferece aos seus aplicativos uma plataforma comum para enviar e receber mensagens, e suas mensagens um lugar seguro para viver até serem recebidas.',
          consul: 'HashiCorp Consul é uma ferramenta de código aberto que resolve essas novas complexidades fornecendo descoberta de serviços, verificações de integridade, balanceamento de carga, um gráfico de serviço, aplicação mútua de identidade TLS e um armazenamento de chave-valor para configurações.',
        },
      },
    },
  },
  en: {
    appBarBtns: {
      whoIam: 'Who I Am',
      projects: 'Projects',
      jobs: 'Jobs',
      knowledge: 'Knowledge'
    },
    notifications: {
      languageUpdated: 'Language changed to English',
      themeUpdatedToLight: 'Theme changed to light',
      themeUpdatedToDark: 'Theme changed to dark',
    },
    components: {
      projectCard: {
        btn: 'View Project',
      },
    },
    sections: {
      home: {
        greetings: 'Hello, I am',
        contactBtn: 'Contact me',
      },
      whoIAm: {
        description: 'I\'m Luiz Adolfo. I am currently working on development of microservices in C# language (.Net Core 3.1+) for Fintech systems, E-commerce and others. In my spare time I work as a freelancer in Front-end(Vue.js) and Back-end(.Net Core). I develop websites, portfolios, landing pages and systems for companies and professional profiles.'
      },
      projects: {
        repositoriesBtn: 'Repositories on GitHub',
        project1: {
          title: 'Ferdado Engenharia',
          description: 'A professional portfolio website for an engineering company.',
        },
        project2: {
          title: 'Patrícia Elizandra',
          description: 'A professional portfolio website for a law firm.',
        },
        project3: {
          title: 'Authorization Interceptor',
          description: 'A simple and lightweight .NET package designed to streamline HttpClient authenticated requests.',
        },
      },
      jobs: {
        job1: {
          title: 'UI/UX Design',
          description: 'I Design and model intuitive and objective interfaces so that the user has a better user experience.',
        },
        job2: {
          title: 'Front-End Developer',
          description: 'I develop responsive and high performance web applications (SPA, PWA and Landing Page) for professional websites, e-commerce, blogs, portfolios, dashboards and administrative panels.',
        },
        job3: {
          title: 'Back-End Developer',
          description: 'I develop fast, modern, secure and high performance APIs in micro-service or monolith.',
        },
      },
      knowledge: {
        description: '*select one of the cards to read*',
        items: {
          vue: 'Vue.js is a open-source JavaScript framework for building user interfaces (UIs) and single-page applications (SPAs).',
          vuetify: 'Vuetify is a complete UI framework built on top of Vue.js. It aims to provide all the tools necessary to create beautiful content rich applications.',
          html5: 'HTML5 is a markup language used for structuring and presenting content on the World Wide Web.',
          js: 'JavaScript is a text-based programming language used both on the client-side and server-side that allows you to make web pages interactive.',
          css: 'CSS (Cascading Style Sheets) describes how HTML elements should be displayed on screen or how they should be styled.',
          jest: 'Jest is a JavaScript testing framework designed to ensure correctness of any JavaScript codebase. It allows you to write tests with an approachable, familiar and feature-rich API that gives you results quickly.',
          npm: 'npm is the world\'s largest Software Registry. The registry contains over 800,000 code packages. Open-source developers use npm to share software.',
          yarn: 'Yarn is a new package manager that replaces the existing workflow for the npm client or other package managers while remaining compatible with the npm registry.',
          github: 'GitHub is an online software development platform used for storing, tracking, and collaborating on software projects. It enables developers to upload their own code files and to collaborate with fellow developers on open-source projects.',
          gitlab: 'GitLab is an open source code repository and collaborative software development platform for large DevOps and DevSecOps projects.',
          sonar: 'SonarQube is an open-source platform developed by SonarSource for continuous inspection of code quality to perform automatic reviews with static analysis of code to detect bugs, code smells on 29 programming languages.',
          grafana: 'Grafana is a multi-platform open source analytics and interactive visualization web application. It provides charts, graphs, and alerts for the web when connected to supported data sources.',
          dotnet: 'NET is a free, cross-platform, open source developer platform for building many different types of applications. With . NET, you can use multiple languages, editors, and libraries to build for web, mobile, desktop, games, IoT, and more.',
          csharp: 'C# is a modern, object-oriented, and type-safe programming language. C# enables developers to build many types of secure and robust applications that run in . NET.',
          efcore: 'Entity Framework (EF) Core is a lightweight, extensible, open source and cross-platform version of the popular Entity Framework data access technology. EF Core can serve as an object-relational mapper (O/RM).',
          nunit: 'NUnit is a unit-testing framework for all .Net languages.',
          identityserver: 'IdentityServer is an authentication server that implements OpenID Connect (OIDC) and OAuth 2.0 standards for ASP.NET Core. It\'s designed to provide a common way to authenticate requests to all of your applications, whether they\'re web, native, mobile, or API endpoints.',
          sqlserver: 'Microsoft SQL Server is a relational database management system (RDBMS) that supports a wide variety of transaction processing, business intelligence and analytics applications in corporate IT environments.',
          postgres: 'PostgreSQL is an advanced, enterprise class open source relational database that supports both SQL (relational) and JSON (non-relational) querying.',
          mongo: 'MongoDB is a document database used to build highly available and scalable internet applications. With its flexible schema approach, it\'s popular with development teams using agile methodologies.',
          redis: 'Redis is an open source (BSD licensed), in-memory data structure store used as a database, cache, message broker, and streaming engine.',
          docker: 'Docker is an open platform for developing, shipping, and running applications. Docker enables you to separate your applications from your infrastructure so you can deliver software quickly.',
          aws: 'AWS (Amazon Web Services) is a comprehensive, evolving cloud computing platform provided by Amazon that includes a mixture of infrastructure as a service (IaaS), platform as a service (PaaS) and packaged software as a service (SaaS) offerings.',
          rabbitmq: 'RabbitMQ is a messaging broker - an intermediary for messaging. It gives your applications a common platform to send and receive messages, and your messages a safe place to live until received.',
          consul: 'HashiCorp Consul is an open source tool that solves these new complexities by providing service discovery, health checks, load balancing, a service graph, mutual TLS identity enforcement, and a configuration key-value store.',
        },
      },
    },
  },
};
