import { render, staticRenderFns } from "./ThemeBtn.vue?vue&type=template&id=3907d44e"
import script from "./ThemeBtn.vue?vue&type=script&lang=js"
export * from "./ThemeBtn.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports